.button {
  width: 116px;
  height: 40px;
  border-radius: 0;
}
.button.button--small {
  width: 116px;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.5px;
  text-align: center;
  color: #fff;
  margin-left: 6px;
 /*total=16px. 10px will be less from .sfu-form__custom_input */
}

.alert-success {
  float: left;
}
.alert {
  border: 1px solid transparent;
  border-radius: 4px;
  width: 73%;
  font-size: 13px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.alert-danger {
  color: #dd3851;
  float: left;
}
.alert-progress {
  width: 100%;
  max-width: 455px;
  display: flex;
  float: inherit;
}

dialog {
  border-color: transparent;
  overflow: hidden;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 0 20px 8px #d0d0d0;
  height: 100px;
  width: 200px;
}