//Support Dashboard
@import 'netapp-library/src/scss/utils/variables';
@import 'netapp-library/src/scss/utils/fonts';

//Widget
$color-green-light: #A7D500;
$color-blue-lightest: #eef9fe;
$color-blue-85: #BAE6F9;
$color-caution: #FEDB00;
$color-coral: #DD3851;
$color-error: $color-coral;
$font-size-widget: 14px;
$icon-size-default: 20px;
$box-shadow-flat: 0 4px 0 0 rgba($color-black, .06);
$transition-speed-default: .3s;
$spacing-stacked-default: 0 0 $spacing-component-bottom 0;
$color-notice-caution: $color-caution;
$color-notice-success: $color-green-light;
$color-notice-error: #FFBD57;
$border-default: 1px solid $color-gray-87;

.n-font-bold {
  @include gotham-bold();
}

.n-widget {
  margin: 0 (-$spacing-sm);
  padding: 0;
  list-style: none;
  columns: 500px 2;
  column-gap: 0;
}

.n-widget__row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -$spacing-sm;
}

.n-widget__row--valign-center {
  align-items: center;
}

.n-widget__row--nowrap {
  flex-wrap: nowrap;
}

.n-widget__col {
  flex: 1;
  padding: $spacing-sm;
  width: 100%;
}

.n-widget__col-1-2 {
  width: 50%;
}

.n-widget__col-icon {
  flex: 0 0 42px;
  text-align: center;
  padding-right: 0;
  padding-left: 0;
}

.n-widget__col--align-right {
  text-align: right;
}

.n-widget__item {
  background-color: $color-white;
  border: $border-default;
  border-top: none;
  margin: 0 $spacing-sm $spacing-default;
  padding: $spacing-default 0 0;
  position: relative;
  width: calc(100% - #{$spacing-default});
  display: inline-block;
  vertical-align: top;

  &::before {
    content: '';
    display: block;
    width: calc(100% + 2px);
    position: absolute;
    left: -1px;
    top: 0;
    background-color: $color-green-light;
    height: 4px;
  }
}

.n-widget__item--is-dragging {
  box-shadow: $drop-shadow-default;
  z-index: 100;
  opacity: .85;
  cursor: move;
}

.n-widget__item--placeholder {
  background-color: $color-gray-97;
  border: dashed 1px $color-gray-87;
  visibility: visible !important;

  &::before {
    display: none;
  }
}

.n-widget__title {
  position: relative;
  margin-bottom: $spacing-component-bottom;
}

.n-widget__title-heading {
  margin: 0;
  text-transform: uppercase;
}

.n-widget__search-input {
  position: absolute;
  width: 0%;
  right: 0;
  top: 6px;
  height: 0;
  overflow: hidden;
  opacity: 0;
  transition: all $transition-speed-default ease-out;
  outline: 4px solid $color-white;
}

.n-widget__search-dismiss {
  position: absolute;
  top: 15px;
  right: 0;
}

.n-widget__search-input--is-open {
  background-color: $color-white;
  display: block;
  width: 100%;
  right: 0;
  height: auto;
  overflow: visible;
  opacity: 1;
}

.n-widget__icon-move {
  fill: $color-gray-61;
  cursor: move;

  .n-widget__item--is-dragging & {
    fill: $color-link-hover;
  }
}

.n-widget__content {
  padding: 0 $spacing-default;
  margin-bottom: $spacing-component-bottom;
}

.n-widget__heading {
  margin: $spacing-stacked-default;

  h6,
  p {
    margin-bottom: 0;
  }
}

.n-widget__stats {
  background-color: $color-blue-lightest;
  padding: $spacing-default;
  margin-bottom: $spacing-component-bottom;
}

.n-widget__stats-stat {
  color: $color-gray-41;
  margin: 0 0 $spacing-sm;
  display: table;

  &:last-child {
    margin-bottom: 0;
  }
}

.n-widget__stats-term {
  display: table-cell;
  padding-right: $spacing-sm;
}

.n-widget__stats-definition {
  display: table-cell;
}

.n-widget__link-all {
  @include gotham-bold;
  font-size: $font-size-widget;
  text-transform: uppercase;
  position: relative;

  &:hover {

    .n-icon {
      fill: $color-link-hover;
    }
  }

  .n-icon {
    position: absolute;
    top: -4px;
    margin-left: 4px;
    padding: 2px;
    display: inline-block;
    height: 24px;
    width: 24px;
  }
}

.n-widget__notification {
  border-bottom: solid 1px $color-gray-87;
  padding: $spacing-lg $spacing-sm;
  margin-bottom: $spacing-component-bottom;
  text-align: center;
  font-size: $font-size-widget;

  .n-compact-data-table-container + & {
    padding-top: $spacing-default;
  }

  p {
    margin-bottom: 0;
    color: $color-gray-41;
    font-size: $font-size-widget;
  }
}

.n-widget__notification--error {
  color: $color-error;

  p {
    color: $color-error;
  }

  .n-icon {
    fill: $color-error;
  }
}

//Compact Data Table
.n-compact-data-table-container {
  overflow: auto;
}

.n-compact-data-table {
  border-collapse: collapse;
  margin-bottom: $spacing-component-bottom;
  font-size: $font-size-widget;
  width: 100%;

  thead {
    background-color: $color-gray-36;
  }

  th {
    padding: $spacing-sm;
    color: $color-white;
    vertical-align: top;
  }

  td {
    padding: $spacing-sm;
    vertical-align: top;
  }

  tbody {

    tr {
      border-bottom: solid 1px $color-gray-87;
    }
  }

  a {
    display: block;
  }
}

.n-compact-data-table__cell--description {
  width: 165px;
}

.n-compact-data-table__number {
  white-space: nowrap;
}

//Type Ahead
.n-type-ahead {
  font-size: $font-size-default;
  margin-bottom: $spacing-component-bottom;
  position: relative;
  text-align: left;

  .n-widget__search-input & {
    width: calc(100% - 29px);
    margin-bottom: 0;
  }
}

.n-type-ahead--is-open {
  z-index: 20;
}

.n-type-ahead__action {
    position: relative;
    z-index: 15;

    &:hover {

    .n-type-ahead__input-icon {
      fill: $color-link-hover;
      pointer-events: none;
    }
  }
}

.n-type-ahead__input {
  @include gotham-narrow;
  appearance: none;
  background-color: $color-gray-97;
  border: $border-default;
  border-radius: $border-radius-sm;
  box-shadow: inset $box-shadow-flat;
  box-sizing: border-box;
  outline: 0;
  color: $color-gray-27;
  width: 100%;
  text-align: left;
  padding: $spacing-sm 32px $spacing-sm $spacing-sm;
  font-size: $font-size-default;
  line-height: 22px;

  &:hover,
  &:focus {
    border-color: $color-link-hover;
  }
}

.n-type-ahead__input-icon {
  height: $font-size-default;
  width: $font-size-default;
  fill: $color-link;
  position: absolute;
  right: $spacing-sm;
  top: calc(50% - 9px});
  z-index: 2;

  .n-type-ahead__input:focus + & {
    fill: $color-link-hover;
  }
}

.n-type-ahead__input-icon--search {
  height: $icon-size-default;
  width: $icon-size-default;
}

.n-type-ahead__menu {
  display: none;
  background-color: $color-white;
  border: $border-default;
  padding: ($spacing-sm + 5px) $spacing-sm $spacing-sm;
  margin-top: -5px;
  box-shadow: $box-shadow-flat;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 10;
  max-height: 425px;
  overflow: auto;

  .n-type-ahead--is-open & {
    display: block;
  }

  .n-link-list {

    &:last-child {
      margin-bottom: 0;

      .n-link-list__item {

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.n-type-ahead__fav-button {
  fill: $color-link;
  margin: 0 4px 4px 0;

  &:hover {

    .n-type-ahead__fav-icon {
      display: none;
    }
    
    .n-type-ahead__fav-icon-fav {
      display: block;
    }

    .n-icon {
      fill: $color-link-hover;
    }
  }

  .n-icon {
    fill: $color-link;
  }
}

.n-type-ahead__fav-button--is-fav {

  .n-type-ahead__fav-icon {
    display: none;
  }
  
  .n-type-ahead__fav-icon-fav {
    display: block;
  }

  &:hover {

    .n-type-ahead__fav-icon {
      display: block;
    }
    
    .n-type-ahead__fav-icon-fav {
      display: none;
    }
  }
}

.n-type-ahead__fav-icon-fav {
  display: none;
}

.n-type-ahead--is-typing {//for prototype simulation not necessary for component production

  .n-link-list__item:nth-child(3),
  .n-link-list__item:nth-child(6) {

    .n-type-ahead__fav-button {

      .n-type-ahead__fav-icon {
        display: none;
      }
      
      .n-type-ahead__fav-icon-fav {
        display: block;
      }
    }
  }
}

//Statistic
.n-statistic {
  margin: $spacing-stacked-default;
  display: flex;
  flex-wrap: nowrap;
  align-items: baseline;
}

.n-statistic__term {
  @include gotham-bold;
  font-size: $font-size-h2-md;
  line-height: 1;
  padding-right: $spacing-xs;

  a {
    @include gotham-bold;
  }
}

.n-statistic__definition {
  margin: 0;
}

.n-compact-tabs {
  margin: $spacing-stacked-default;
}

.n-compact-tabs__row {
  border-bottom: solid 1px $color-gray-87;
  list-style: none;
  padding: 0;
  margin: $spacing-stacked-default;
  display: flex;
  flex-wrap: nowrap;
  align-items: baseline;
}

.n-compact-tabs__item {
  padding: 0 $spacing-default 0 0;

  &:first-child {
    padding-right: $spacing-sm;
  }

  &:last-child {
    padding-right: 0;
  }
}

.n-compact-tabs__label {
  @include gotham-bold;
  border-bottom: 4px solid transparent;
  display: inline-block;
  padding-bottom: $spacing-xs;
  font-size: $font-size-widget;
}

.n-compact-tabs__tab {
  background: none;
  border: 0;
  border-bottom: 4px solid transparent;
  color: $color-link;
  cursor: pointer;
  font: inherit;
  margin: 0;
  outline: 0;
  overflow: visible;
  padding: 0 0 $spacing-xs;
  text-align: inherit;
  display: inline-block;
  transition: border-color $transition-speed-default ease-out;

  &:hover {
    color: $color-link-hover;
  }
}

.n-compact-tabs__tab--is-active {
  color: $color-link-hover;
  border-bottom: 4px solid $color-link-hover;
}

.n-compact-tabs__tab-content {
  display: none;
}

.n-compact-tabs__tab-content--is-active {
  display: block;
}

//Chart
$chart-size: 145px;
$chart-key-size: 16px;
$color-chart-green-1: $color-green-light;
$color-chart-green-2: #96BE01;
$color-chart-green-3: #6D8C00;
$color-chart-green-4: #546B00;
$color-chart-blue-1: #BAE6F9;
$color-chart-blue-2: #8DC8E8;
$color-chart-blue-3: #48ABF3;
$color-chart-red-1: $color-error;
$color-chart-yellow-1: $color-caution;
$color-chart-yellow-2: #F39400;
$color-chart-yellow-3: $color-notice-error;
$color-chart-yellow-4: #FAEC96;

.n-chart {
  margin: $spacing-stacked-default;
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
}

.n-chart__cell {
  padding: 0 $spacing-default;
  margin: 0;
  position: relative;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
}

.n-chart__chart {
  width: $chart-size;
  height: $chart-size;
  transform: rotate(-90deg);
  transform-origin: center;
}

.n-chart__chart-ring {
  fill: transparent;
  stroke: $color-gray-64;
  transform-origin: center;
}

.n-chart__chart-ring--success-1 {
  stroke: $color-chart-green-1;
}

.n-chart__chart-ring--success-2 {
  stroke: $color-chart-green-2;
}

.n-chart__chart-ring--success-3 {
  stroke: $color-chart-green-3;
}

.n-chart__chart-ring--success-4 {
  stroke: $color-chart-green-4;
}

.n-chart__chart-ring--info-1 {
  stroke: $color-chart-blue-1;
}

.n-chart__chart-ring--info-2 {
  stroke: $color-chart-blue-2;
}

.n-chart__chart-ring--info-3 {
  stroke: $color-chart-blue-3;
}

.n-chart__chart-ring--error-1 {
  stroke: $color-chart-red-1;
}

.n-chart__chart-ring--caution-1 {
  stroke: $color-chart-yellow-1;
}

.n-chart__chart-ring--caution-1 {
  stroke: $color-chart-yellow-1;
}

.n-chart__chart-ring--caution-2 {
  stroke: $color-chart-yellow-2;
}

.n-chart__chart-ring--caution-3 {
  stroke: $color-chart-yellow-3;
}

.n-chart__chart-ring--caution-4 {
  stroke: $color-chart-yellow-4;
}

.n-chart__legend {
  margin: $spacing-stacked-default;

  &:last-child {
    margin: 0;
  }
}

.n-chart__legend-label {
  @include gotham-bold;
  margin: 0 0 $spacing-xs;
}

.n-chart__legend-stat-list {
  margin: 0;
  padding: 0;
}

.n-chart__stat-table {
  display: table;
  margin-bottom: $spacing-sm;
}

.n-chart__stat-table--small {
  width: 100%;
}

.n-chart__stat-row {
  display: table-row;
  list-style: none;

  .n-chart__stat-table--small & {
    font-size: $font-size-widget;
  }
}

.n-chart__stat-cell {
  display: table-cell;
  padding: 2px $spacing-xs;
  vertical-align: top;
  text-align: left;

  &:first-child {
    padding-left: 0;
  }
}

.n-chart__stat-cell--key {
  padding-left: 0;
  padding-top: 5px;
  width: 22px;
}

.n-chart__stat-cell--number {
  text-align: right;
  // width: 45px;
}

.n-chart__stat-cell--ratio {
  text-align: right;
  padding-right: 34px;
}

.n-chart__sublegend {
  padding-left: 30px;
  font-size: $font-size-widget;
}

.n-chart__key {
  display: block;
  width: $chart-key-size;
  height: $chart-key-size;
  border-radius: 50%;
  background-color: $color-gray-64;
}

.n-chart__key--success {
  background-color: $color-chart-green-1;
}

.n-chart__key--success-2 {
  background-color: $color-chart-green-2;
}

.n-chart__key--success-3 {
  background-color: $color-chart-green-3;
}

.n-chart__key--success-4 {
  background-color: $color-chart-green-4;
}

.n-chart__key--info {
  background-color: $color-chart-blue-1;
}

.n-chart__key--info-2 {
  background-color: $color-chart-blue-2;
}

.n-chart__key--info-3 {
  background-color: $color-chart-blue-3;
}

.n-chart__key--error {
  background-color: $color-chart-red-1;
}

.n-chart__key--caution {
  background-color: $color-chart-yellow-1;
}

.n-chart__key--caution-2 {
  background-color: $color-chart-yellow-2;
}

.n-chart__key--caution-3 {
  background-color: $color-chart-yellow-3;
}

.n-chart__key--caution-4 {
  background-color: $color-chart-yellow-4;
}

.n-chart__note {
  color: $color-gray-41;
  font-size: $font-size-widget;
}

.n-chart__note--is-block {
  display: block;
}

.n-chart__link {
  @include gotham-bold;
}

//Tooltip
.n-tooltip {
  position: relative;

  &:hover,
  &.n-tooltip--is-open {

    .n-tooltip__content {
      display: block;
    }
  }
}

.n-tooltip__content {
  @include gotham-narrow();
  font-size: $font-size-widget;
  text-transform: none;
  text-align: center;
  line-height: 1.25;
  color: $color-text-default;
  background-color: $color-white;
  padding: $spacing-sm;
  border: 1px solid $color-gray-64;
  border-radius: $border-radius-sm;
  box-shadow: $box-shadow-flat;
  display: none;
  content: attr(data-tooltip);
  position: absolute;
  top: calc(100% + 10px);
  left: -40px;
  width: 175px;
  // max-width: 250px;
  z-index: 40;

  &::before {
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 10px solid $color-gray-64;
    display: block;
    content: '';
    position: absolute;
    bottom: 100%;
    left: 41px;
  }

  &::after {
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 8px solid $color-white;
    display: block;
    content: '';
    position: absolute;
    bottom: 100%;
    left: 43px;
  }
}

//Notice
.n-notice {
  margin: $spacing-stacked-default;
  background-color: $color-gray-36;
  color: $color-white;
}

@keyframes slideupnotice {
  0% {
    max-height: 100px;
  }

  100% {
    max-height: 0px;
  }
}

.n-notice--is-hidden {
  overflow: hidden;
  animation: slideupnotice $transition-speed-default ease-in forwards;
}

.n-notice__table {
  color: $color-white;
  width: 100%;
  display: table;
  list-style: none;
  padding: 0;
}

.n-notice__cell {
  display: table-cell;
  padding: 20px $spacing-xs;
  vertical-align: top;
  overflow: hidden;
}

.n-notice__cell--action {
  text-align: center;
  width: 45px;
}

.n-notice__cell--slide-number {
  text-align: right;
}

.n-notice__slide-number {
  font-size: $font-size-widget;
}

.n-slide-number {
  white-space: nowrap;
}

.n-notice__button {

  &:hover {

    .n-icon {
      fill: $color-blue-85;
    }
  }

  .n-icon {
    fill: $color-white;
  }
}

.n-notice__link {
  color: $color-blue-85;

  &:hover {
    color: $color-white;
  }
}

.n-notice__content {
  position: relative;
  padding-left: 30px;
}

.n-notice__icon {
  position: absolute;
  left: 0;
  top: 2px;
  fill: $color-white;

  // .n-notice__content--error & {
  //   fill: $color-notice-error;
  // }

  // .n-notice__content--success & {
  //   fill: $color-notice-success;
  // }

  &:hover {
    fill: $color-white;

    // .n-notice__content--error & {
    //   fill: $color-notice-error;
    // }

    // .n-notice__content--success & {
    //   fill: $color-notice-success;
    // }
  }
}

.n-notice__frame {
  display: block;
  margin: 0;
  padding: 0;
  list-style: none;
  color: $color-white;
  position: relative;
}

.n-notice__slide {
  display: none;
  position: relative;
}

.n-notice__slide--is-active {
  display: block;
}

@keyframes slideinleftnotice {
  0% {
    left: 100%;
  }

  100% {
    left: 0;
  }
}

.n-notice__slide--slide-in-left {
  animation: slideinleftnotice $transition-speed-default ease-out;
}

@keyframes slideinrightnotice {
  0% {
    right: 100%;
  }

  100% {
    right: 0;
  }
}

.n-notice__slide--slide-in-right {
  animation: slideinrightnotice $transition-speed-default ease-out;
}

@keyframes slideoutleftnotice {
  0% {
    right: 0;
  }

  100% {
    right: 110%;
  }
}

.n-notice__slide--slide-out-left {
  position: absolute;
  display: block;
  top: 0;
  width: 100%;
  animation: slideoutleftnotice $transition-speed-default ease-out forwards;
}

@keyframes slideoutrightnotice {
  0% {
    left: 0;
  }

  100% {
    left: 110%;
  }
}

.n-notice__slide--slide-out-right {
  position: absolute;
  display: block;
  top: 0;
  width: 100%;
  animation: slideoutrightnotice $transition-speed-default ease-out forwards;
}

//Loader simulation
.n-loader {
  background-color: rgba($color-white, .95);
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: none;
}

.n-loader--is-visible {
  display: block;
}

.n-loader__message {
  font-size: $font-size-xl;
  color: $color-gray-61;
  text-align: center;
  position: absolute;
  top: 50%;
  width: 100%;
}

//Icon
.n-icon--default {
  height: $icon-size-default;
  width: $icon-size-default;
  vertical-align: middle;

  a &,
  button & {
    fill: $color-link;
  }
}

.n-icon--medium {
  height: $font-size-widget;
  width: $font-size-widget;
  vertical-align: middle;
}

.n-icon--large {
  height: $font-size-default;
  width: $font-size-default;
  vertical-align: middle;
}

//Link List
.n-link-list--lg {
  font-size: $font-size-default;

  .n-link-list__link {
    display: inline-block;
    font-size: $font-size-default;
    font-weight: 300;
    line-height: 20px;
  }
}

.n-link-list__item--heading {
  font-weight: 700;
  margin-bottom: $spacing-xs;

  .n-link-list__link {
    font-weight: 700;
  }
}

.n-link-list__mark {
   background-color: transparent;
   color: $color-link;

  .n-type-ahead--is-typing &,
  .n-search-form--is-typing & {
    font-weight: 700;
  }
}

//Button
.button {
  border: 0;
  line-height: 1.5;
  outline: none;
  cursor: pointer;
}

.button--small {
  font-size: $font-size-widget;
  padding: 10px 20px;
}

.button--clean {
  color: $color-link;
  padding: 0;
  border: none;
  background: transparent;
  vertical-align: middle;
  outline: none;
  line-height: 1;

  &:hover {
    background: transparent;
    color: $color-link-hover;
  }
}

//Footer
.n-footer__property-link-list-header {
  margin-bottom: 0;
}

//Top Hat
.n-top-hat__utils {

  .n-top-hat__list-item {
    border: none;
  }
}

.n-top-hat__profile-link {
  padding: 0;
  outline: 0;

  &.n-top-hat__profile-link--is-active {
    color: $color-link;
    position: relative;

    &::before {
      position: absolute;
      content: '';
      display: block;
      width: 100%;
      background: $color-white;
      height: 6px;
      width: 100%;
      top: -6px;
    }

    &::after {
      position: absolute;
      content: '';
      display: block;
      width: 100%;
      background: $color-white;
      height: 6px;
      width: 100%;
      bottom: -6px;
    }

    .n-icon {
      fill: $color-link;
    }
  }

  .n-icon {
    fill: $color-white;
  }
}

.n-top-hat__profile-link--is-active {
  background: $color-white;
}

.n-top-hat__link-text {
  display: inline;
}

.n-top-hat__profile-menu {
  top: 24px;
  right: 6px;
  border: $border-default;
  border-top: none;
  box-shadow: $box-shadow-flat;
  padding: $spacing-sm 0;
}

.n-top-hat__profile-menu-list-label {
  color: $color-gray-41;
  display: block;
  padding: $spacing-xs $spacing-default;
  font-weight: 400;
}

.n-top-hat__profile-menu-list-link {
  color: $color-link;
}

.n-top-hat__menu-indicator {
  top: -2px;
  position: relative;

  .n-top-hat__profile-link--is-active & {
    top: 1px;
  }
}

//Property Navigation Bar
.n-property-navigation-bar__utils-link--secondary {
  @include gotham-narrow-book;
  color: $color-link;

  &:hover {
    text-decoration: none;

    .n-icon {
      fill: $color-link-hover;
    }
  }

  .n-icon {
    fill: $color-link;
    height: 16px;
    width: 16px;
    margin-bottom: 4px;
    vertical-align: middle;
  }

  .n-property-navigation-bar__utils-link-text {
    text-transform: none;
  }
}

//Property Bar
.n-property-bar__narrow-action-search-form {

  .n-property-bar--narrow-search-form-visible & {
    display: block;
  }
}

.n-property-bar__narrow-actions-dropdown {
  padding: 0 $spacing-xs;
}

.n-property-bar__narrow-actions-dropdown-button {
  position: relative;
  padding-right: 18px;

  &:hover {

    .n-icon {
      fill: $color-link-hover;
    }
  }

  .n-icon {
    fill: $color-gray-41;
  }
}

.n-property-bar__narrow-actions-dropdown-menu {
  display: none;
  background-color: $color-gray-97;
  box-shadow: $box-shadow-flat;
  padding: $spacing-sm 0;
  position: absolute;
  top: 38px;
  left: 0;
  width: 100%;

  .n-property-bar__narrow-actions-dropdown--is-open & {
    display: block;
    z-index: 300;
  }

  .n-top-hat__profile-menu-list {
    margin: 0 -9px;
  }
}

.n-property-bar__narrow-actions-dropdown-indicator {
  position: absolute;
  top: 3px;
  right: 0;

  .n-property-bar__narrow-actions-dropdown--is-open & {
    transform: rotate(180deg);
    top: 7px;
  }
}

.n-property-bar__toolbar {

  .n-off-canvas-menu--open & {
    display: none;
  }
}

//Page Header
.n-site-header {
  border-bottom: $border-default;
}

.n-page-header__table {

  @media screen and (min-width: $screen-md) {
    margin: $spacing-stacked-default;
    display: table;
    width: 100%;

    .n-page-header__heading {
      margin-bottom: 0;
    }
  }
}

.n-page-header__cel {

  @media screen and (min-width: $screen-md) {
    display: table-cell;
    padding: $spacing-xs;
    vertical-align: middle;

    .n-type-ahead {
      margin-bottom: 0;
    }
  }
}

.n-page-header__cel--text-right {

  @media screen and (min-width: $screen-md) {
    text-align: right;
  }
}

.n-page-header__site-name {
  font-size: $font-size-lg;
  font-weight: 700;
  margin: $spacing-stacked-default;

  @media screen and (min-width: $screen-md) {
    margin: 0;
  }
}

//Menu
.n-menu {
  box-shadow: $box-shadow-flat;
  padding: $spacing-default 0;
  border: $border-default;
  width: auto;

  .n-site-header--large & {

    @media (min-width: $screen-md) {
      top: 165px;
    }
  }
}

.n-menu__link {
  font-weight: 300; 
}

.n-menu__list-section {
  padding: 0 $spacing-default;

  > .n-menu__list > .n-menu__list-item {
    padding: 0;
  }
}

//Off Canvas Nav
.n-off-canvas-menu__global-navigation-list {
  background: transparent;
}

.n-off-canvas__cross-property-nav {
  padding: 20px;
}

.n-off-canvas-menu__property-navigation-link {
  color: $color-link;
}

.n-off-canvas-menu__property-navigation-list-item--property {

  .n-off-canvas-menu__property-navigation-link {
    text-transform: capitalize;
    font-size: $font-size-lead-md;
    font-weight: 300;
  }
}

.n-off-canvas-menu__property-navigation-list-item--lowercase {

  .n-off-canvas-menu__property-navigation-link {
    font-weight: 300;
    text-transform: capitalize;

    .n-icon {
      fill: $color-link;
      position: relative;
      top: -3px;
    }

    &:hover {

      .n-icon {
        fill: $color-link-hover;
      }
    }
  }
}

.n-off-canvas-menu__property-navigation-link-icon {
  height: 12px;
  width: 12px;
  top: 25px;
}

//Doc Styles
.n-doc-item {
  margin-bottom: $spacing-xl;
}

.n-doc-header {
  text-transform: uppercase;
  color: $color-gray-64;
  font-size: $font-size-xl;
  border-bottom: $border-default;
  padding-bottom: $spacing-sm;
}

//Search Form
.n-search-form__container {
  position: relative;
  z-index: 50;
}

.n-search-form {
  position: relative;
  z-index: 15;
}

.n-search-form__input {
  appearance: none;

  @media screen and (min-width: $screen-lg) {
    width: 456px;
  }
}

.n-search-form__menu {
  display: none;
  background-color: $color-white;
  border: $border-default;
  margin-top: -5px;
  box-shadow: $box-shadow-flat;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 10;
  max-height: 425px;
  overflow: auto;
  text-align: left;
  flex-wrap: nowrap;

  .n-search-form__menu--is-open & {
    display: flex;
  }

  .n-link-list {

    &:last-child {
      margin-bottom: 0;

      .n-link-list__item {

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.n-search-form__menu-col {
  flex: 1 1 50%;
  padding: $spacing-default;

  &:first-child:not(:only-child) {
    border-right: $border-default;
  }
}

.n-search-form__menu-col--filters {
  position: relative;
  padding-bottom: 65px;
}

.n-search-form__close {
  position: absolute;
  bottom: $spacing-default;
  right: $spacing-default;
}
